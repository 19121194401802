.tile {
    position: relative;
    width: 64px;
    height: 64px;
}

.galaxyTilesWrapper {
  background: linear-gradient(20deg, transparent, transparent), url(https://grainy-gradients.vercel.app/noise.svg);
}

.point {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 2px;
    background: #ffffff;
    filter: blur(0.5px);
    border-radius: 46px;
    transform: translate(-50%, -50%);
    opacity: 0.4;
}

.diamond {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 76px;
    height: 76px;
    background: radial-gradient(50% 50% at 50% 50%, #f2f2f2 0%, rgba(242, 242, 242, 0) 100%);
    opacity: 0;
    filter: blur(8px);
    transform: translate(-50%, -50%);
    transition: opacity 500ms;
}

.halo {
    position: absolute;
    width: 76px;
    height: 76px;
    top: -4px;
    left: -10px;
    background: radial-gradient(49.7% 49.7% at 50% 50%, #2FC09F 0%, rgba(47, 192, 159, 0.36) 100%);
    filter: blur(17.5px);
    z-index: 10;
    opacity: 0;
    transition: opacity 200ms;
}

.tile:hover .diamond {
    opacity: 0.3;
    transition-delay: 0s;
}

.tile:not(:hover) .diamond {
    opacity: 0;
    transition-delay: 800ms; /* 1 second delay when not hovering */
  }

.tile:hover .halo {
    opacity: 1;
    transition-delay: 0s;
}

.tile:not(:hover) .halo {
    opacity: 0;
    transition-delay: 800ms; /* 1 second delay when not hovering */
  }
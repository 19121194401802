.aTrueMasterpiece {
  margin: 0;
}
.aTrueMasterpieceContainer,
.eetFortunesAre {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.headerContainer {
  line-height: 110%;
  text-transform: uppercase;
  color: var(--eet-green);
}
.eetFortunesAre {
  font-size: var(--xl-body-text-size);
  line-height: 140%;
}
.getYourNft {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}
.getYourNftWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green);
  box-shadow: 0 0 20px rgba(47, 192, 159, 0.8);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
  cursor: pointer;
  margin-top: 16px;
  transition: box-shadow 0.2s ease-in-out;

}

.getYourNftWrapper:hover {
  box-shadow: 0px 0px 40px rgba(47, 192, 159, 0.8);
}

.contentleft {
  width: 520px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-37xl) var(--padding-21xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.fortuneAssembly {
  position: relative;
  width: 600px;
  flex-shrink: 0;
  object-fit: cover;
}

.courtesyCaiStudio {
  position: relative;
  line-height: 150%;
}
.fortuneAssemblyWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
}
.contentleftParent,
.frameWrapper,
.laddersection {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameWrapper {
  width: 640px;
  flex-shrink: 0;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-2xs);
  color: var(--gray-5);
  font-family: var(--font-monaco);
}
.contentleftParent,
.laddersection {
  width: 100%;
  justify-content: space-between;
}
.laddersection {
  margin-top: -120px;
  max-width: var(--content-width);
  width: 100%;
  flex-direction: column;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--gray-5);
  font-family: var(--xl-body-text);
}

@media screen and (max-width: 980px) {
  .contentleftParent {
    width: 100%;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .contentright {
    width: 100%;
  }

  .contentleft {
    max-width: 100%;
  }

  .frameWrapper {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 88px;
  }

  .fortuneAssemblyWrapper {
    max-width: 100%;
    align-items: center;
  }

  .fortuneAssembly {
    max-width: 120%;
    height: auto;
  }

  .laddersection {
    max-width: 100vw;
    display: flex;
    width: 100vw;
    padding: 0;
  }
  
}

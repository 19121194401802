.gradientBlackGrey {
  position: relative;
  width: 100vw;
  min-height: 200px;
  flex-shrink: 0;
  object-fit: cover;
  margin-top: -5px;
  margin-bottom: -5px;
}

.gradientBlackGreyReversed {
  position: relative;
  width: 100vw;
  min-height: 200px;
  flex-shrink: 0;
  object-fit: cover;
  margin-top: -5px;
  margin-bottom: -5px;
  transform: rotate(180deg);
}

.about {
  margin: 0;
}
.headline {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 110%;
  text-transform: uppercase;
  color: var(--purple);
  display: inline-block;
}
.caiAlienIcon {
  position: relative;
  width: 890px;
  height: 534px;
  flex-shrink: 0;
  object-fit: cover;
}
.copy,
.quote {
  display: inline-block;
}

.quote {
  position: relative;
  font-size: var( --xl-subhead-size);
  line-height: 160%;
  width: 494px;
}
.copy {
  font-size: var(--xl-body-text-size);
  text-align: left;
  width: 640px;
}
.copy,
.subline,
.website {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
}

.caption {
  font-family: 'Monaco';
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: var(--gray-1);
  opacity: 0.7;
  width: 100%;
  margin-top: -32px;
}
.subline {
  font-size: var(--xl-body-text-size);
  font-family: var(--xl-body-text);
}
.website {
  border-bottom: 1px solid #000000;
  cursor: pointer;
}
.socials {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}
.caisection,
.socialwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.socialwrapper {
  margin-top: 64px;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--gray-2);
  font-family: var(--font-monaco);
}

.caisection {
  width: 1272px;
  gap: var(--gap-46xl);
}

.headline1 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 110%;
  text-transform: uppercase;
  display: inline-block;
  height: 35px;
  flex-shrink: 0;
}
.httpskanonart {
  text-decoration: underline;
}
.copy1 {
  position: relative;
  font-size: var(--xl-body-text-size);
  letter-spacing: 0.02em;
  line-height: 140%;
  color: var(--gray-1);
  text-align: left;
  display: inline-block;
  width: 640px;
}
.kanonsection {
  margin-top: 64px;
  align-items: center;
  gap: var(--gap-46xl);
  color: var(--purple);
}
.contentwrapper,
.creatorsection,
.kanonsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contentwrapper {
  align-items: center;
  gap: var(--gap-xxl);
}
.creatorsection {
  width: 100%;
  display: flex;
  background-color: var(--gray-5);
  align-items: center;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--gray-1);
  font-family: var(--xl-body-text-size);
}

.readMoreButton {
  margin-top: 24px;
  border-bottom: 1px solid #000000;
  opacity: 0.8;
}



@media screen and (max-width: 980px) {
  .caiAlienIcon {
    max-width: 80vw;
    height: auto;
  }
  .copy {
    max-width: 480px;
    width: unset;
    padding: 0 24px 24px 24px;
  }

  .caisection {
    max-width: 480px;
    width: 100%;
  }

  .about {
    max-width: 100%;
    width: 100%;
  }

  .quote {
    max-width: 480px;
    width: unset;
    padding: 0 24px 24px 24px;
  }
  
  .copy1 {
    max-width: 480px;
    width: unset;
    padding: 0 24px 24px 24px;
  }
}

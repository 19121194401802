.xl {
  position: relative;
  background-color: var(--colors-black);
  width: 100vw;
  display: flex;
  overflow: hidden;
  gap: var(--gap-xxl);
  text-align: center;
  justify-content: center;
  font-size: var(--xl-body-text-size);
  color: var(--gray-6);
  font-family: var(--xl-body-text);
}

.aboutsection {
  display: flex;
  max-width: var(--content-width);
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xxl);
}

.gradient {
  position: relative;
  left: 0;
  width: 100vw;
  object-fit: cover;
  margin-bottom: -5px;
  min-height: 200px;
}

.gradientReversed {
  position: relative;
  left: 0;
  width: 100vw;
  object-fit: cover;
  margin-bottom: -5px;
  min-height: 200px;
  transform: rotate(180deg);
}

.artistinnersection {
  background-color: var(--grey-5);
  gap: var(--gap-xxl);
}

.artistinnersection,
.artistsection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.artistsection {
  width: 100%;
  gap: 0;
  justify-content: center;
  background-color: var(--gray-5);
}

.artistinnersection {
  width: 100%;
}

.artistinnersection {
  width: 100%;
  margin-top: -5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: -5px;
}

.caiGuoQiangWas {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
  text-transform: uppercase;
}
.guggenheimMuseumLogo1Icon,
.diagram {
  position: relative;
  width: 132.98px;
  height: auto;
  flex-shrink: 0;
}

.image1Icon {
  width: 88px;
  object-fit: cover;
}
.image1Wrapper {
  width: 100px;
  flex-shrink: 0;
  display: flex;
  height: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.netflixLogo {
  position: relative;
  width: 100px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
}

.timesLogo {
  position: relative;
  width: 140px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
}

.logoParent {
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 64px;
  margin-bottom: 48px;
}
.caiGuoQiangWasFeaturedByParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  max-width: 100%;
  color: var(--gray-1);
}
.howItWorks {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 110%;
  text-transform: uppercase;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}
.thinkOfEet {
  margin: 0;
}
.thinkOfEetContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--xl-body-text-size);
  letter-spacing: 0.02em;
  line-height: 140%;
  color: var(--gray-6);
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
}
.contentleft {
  flex: 1;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-37xl) var(--padding-21xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.diagram {
  position: relative;
  max-width: 640px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.diagramsection,
.diagramWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.diagramWrapper {
  flex: 1;
}
.diagramsection {
  max-width: var(--content-width);
  width: 100%;
  font-size: var(--font-size-13xl);
  color: var(--eet-green3);
  margin-top: -80px;
}
.twitterIcon {
  position: relative;
  width: 20px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
}
.twitterParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.twitterParent:first-child{
  padding-top: 2px;
}

.builtBy,
.kanon {
  position: relative;
  line-height: 150%;
}
.kanon {
  color: var(--gray-6);
  cursor: pointer;
}
.metadateMint {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
}
.footer,
.xl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footer {
  width: 468px;
  padding: 0 0 var(--padding-xxl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  font-size: var(--font-size-sm);
  color: var(--gray-3);
  font-family: var(--font-monaco);
}

@media screen and (max-width: 980px) {
  .diagramsection {
    margin-top: 0px;
    flex-direction: column;
    gap: 0px;
  }

  .contentleft {
    padding: 0 24px;
  }

  .diagram {
    max-width: 120%;
    width: 120%;
    height: auto;
    padding: 24px;
    margin-top: -32px;
  }

  .guggenheimMuseumLogo1Parent {
    padding: 24px;
  }

  .guggenheimMuseumLogo1Parent > * {
    max-width: 80px;
  }

  .artistsection {
    margin-bottom: -200px;
    margin-top: 80px;
  }

  .gradient {
    margin-top: -5px;
  }

  .caiGuoQiangWas {
    padding: 0 64px;
  }
  
}

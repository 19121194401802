.separator {
  position: relative;
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
}
.aNovelOracle {
  margin: 0;
}
.aNovelOracleContainer,
.eetIsThe {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.aNovelOracleContainer {
  line-height: 110%;
  text-transform: uppercase;
}
.eetIsThe {
  font-size: var(--xl-body-text-size);
  line-height: 140%;
  color: var(--gray-6);
}
.startSession {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}
.startSessionWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green3);
  box-shadow: 0 0 20px rgba(47, 192, 159, 0.8);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.startSessionWrapper:hover {
  box-shadow: 0px 0px 40px rgba(47, 192, 159, 0.8);
}

.contentleft {
  width: 520px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-37xl) var(--padding-21xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.fortuneShowreel {
  position: relative;
  width: 520px;
  height: auto;
  object-fit: cover;
}

.oraclesection {
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--eet-green3);
  font-family: var(--xl-body-text);
}

@media only screen and (max-width: 980px) {
  .oraclesection {
    flex-direction: column;
  }

  .contentleft {
    max-width: 480px;
    padding-bottom: 48px;
    width: 100%;
  }

  .image11Icon {
    width: 100%;
    height: auto;
  }
}

.menuButton {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menuButtonOpen {
    opacity: 1;
}

.elementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
    font-family: var(--font-monaco);
}

.navLink {
    text-decoration: none;
    color: white;
    opacity: 0.6;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: var(--xl-body-text);
}

.navLink:hover {
    opacity: 1;
    transition: all;
    transition-duration: 300ms;
}

.oracleLink {
    margin-right: 12px;
}

.walletLink {
    margin-right: 12px;
}

.mobileMenu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: 2px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px;
    padding-bottom: 32px;
    z-index: 40;
}

.homeLink {
    color: var(--eet-green);
    font-size: 20px;
    cursor: pointer;
}

.menuButtonContainer {
    position: absolute;
    top: 18px;
    right: 16px;
}

@media only screen and (max-width: 700px) {
    .elementWrapper {
        gap: 8px;
    }

    .navLink {
        color: var(--eet-green);
        font-size: 20px;
        opacity: 1;
        text-align: center;
    }

    .homeLink {
        text-align: center;
        margin-right: 0px;
    }

    .oracleLink {
        margin-right: 0px;
    }

    .walletLink {
        margin-right: 0px;
    }

    .mobileMenu {
        gap: 24px;
    }
}
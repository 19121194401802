@font-face {
  font-family: "Boogie School Sans";
  src: url("/public/BoogieSchoolSans-High3rd.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Monaco";
  src: url("/public/Monaco-Regular.ttf");
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  cursor: crosshair;
  line-height: normal;
  background-color: var(--colors-black);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  all: unset;
}

button {
  all: unset;
  cursor: pointer;
}

.tooltip {
  position: relative;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--eet-green);
  color: var(--colors-black);
  padding: 4px 12px;
  white-space: nowrap;
  font-size: 14px;
  pointer-events: none;
  border-radius: 24px;

}

:root {
  /* fonts */
  --font-monaco: Monaco;
  --xl-body-text: "Boogie School Sans";

  /* font sizes */
  --font-size-sm: 14px;
  --xl-subhead-size: 18px;
  --font-size-13xl: 32px;
  --xl-body-text-size: 15px;
  --font-size-base: 16px;
  --font-size-xs: 12px;
  --font-size-2xs: 11px;
  --font-size-17xl: 42px;
  --sm-header-size: 28px;

  /* Widths */

  --content-width: 1200px;
  --mobile-height: 100vh;

  /* Colors */
  --colors-black: #000000;
  --gray-3: #828282;
  --color-gray-100: rgba(0, 0, 0, 0.2);
  --gray-6: #f2f2f2;
  --eet-green: #2fc09f;
  --gray-5: #e0e0e0;
  --eet-green3: #2fc09f;
  --gray-5: #E0E0E0;
  --gray-1: #333333;
  --purple: #735CE5;

  /* Gaps */
  --gap-xxl: 120px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-53xl: 72px;
  --gap-9xl: 24px;
  --gap-46xl: 48px;

  /* Paddings */
  --padding-xxl: 120px;
  --padding-21xl: 40px;
  --padding-293xl: 312px;
  --padding-5xs: 8px;
  --padding-61xl: 80px;
  --padding-base: 16px;
  --padding-16xl: 35px;
  --padding-37xl: 56px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-45xl: 64px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-base: 16px;
}

@media only screen and (max-width: 1520px) {
  :root {
    --font-size-17xl: 32px;
    --xl-subhead-size: 16px;
  }
}

@media screen and (max-width: 980px) {

  :root {
    --padding-37xl: 24px;
    --padding-61xl: 24px;
    --font-size-13xl: 28px;
    --font-size-17xl: 28px;
    --xl-subhead-size: 15px;
  }

}
.faqsection {
  margin-top: -64px;
  align-items: flex-start;
  text-align: center;
  width: 100vw;
  font-size: var(--font-size-13xl);
  color: white;
  font-family: var(--xl-body-text);
}

.faqinnersection,
.faqsection,
.faqwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.faqinnersection {
  background-color: var(--eet-green3);
  width: 100%;
  padding: var(--padding-21xl) var(--padding-293xl);
  box-sizing: border-box;
  margin-top: -5px;
  margin-bottom: -5px;
  align-items: center;
  gap: var(--gap-13xl);
}

.faqwrapper {
  max-width: var(--content-width);
  align-items: center;
  gap: var(--gap-base);
  text-align: left;
  display: flex;
  justify-content: center;
  font-size: var(--xl-subhead-size);
}

.gradient {
  position: relative;
  width: 100vw;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 200px;
  transform: rotate(180deg);
}

.reversed {
  position: relative;
  width: 100vw;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 200px;
}

.faq,
.whatIsEet {
  position: relative;
  letter-spacing: 0.02em;
}
.faq {
  align-self: stretch;
  line-height: 110%;
  text-transform: uppercase;
}
.whatIsEet {
  flex: 1;
  line-height: 140%;
}
.keyboardArrowDown {
  position: relative;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  overflow: hidden;
}
.div,
.whatIsEetParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.whatIsEetParent {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-9xs);
}
.div {
  border-radius: var(--br-5xs);
  border: 1px solid white;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: center;
}

@media screen and (max-width: 980px) {

  .faqinnersection {
    width: 100vw;
    padding: 0 24px;
  }

  .faqwrapper {
    width: 100%;
  }
}


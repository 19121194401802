.faqSection {
    padding: 1rem;
    color: white;
    width: 600px;
  }
  
  .accordion {
    border: 1px solid #ffffff;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .topAccSection {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  .bottomAccSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .answers {
    margin-top: 24px;
  }
  
  .arrow {
    transition: transform 0.3s ease;
    margin-left: 64px;
  }
  
  .rotated {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 980px) {

    .faqSection {
      padding: 0;
      width: 90vw;
    }
  }
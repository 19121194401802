  .galaxyOuterWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .galaxyInnerWrapper {
    overflow: hidden;
    width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .galaxyImgWrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(20deg, transparent, transparent), url(https://grainy-gradients.vercel.app/noise.svg);
    opacity: 0.2;
  }

  .galaxy {
    padding-top: 24px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    opacity: 70%;
    z-index: 1;
  }

  .yellow {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(30%, 30%);
    opacity: 0.7;
  }

  .purple {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.15;
    width: 100vw;
    height: 100vh;
  }

  .red {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-10%, -30%);
    opacity: 0.8;
  }

  .green {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-20%, 30%);
    opacity: 0.7;
  }

  .blue {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0%, -20%);
    opacity: 0.8;
  }

  @media screen and (max-width: 980px) {
    .galaxyOuterWrapper {
      pointer-events: none;
    }

    .galaxyImgWrapper {
      display: none;
    }

    .galaxy {
      pointer-events: none;
    }

    .yellow {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(30%, 30%);
      opacity: 0.1;
    }

    .purple {
      height: var(--mobile-height);
      width: auto;
    }

  }
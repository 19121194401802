.frameChild {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.frameWrapper {
  width: 520px;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
}

.courtesyCaiStudio {
  position: relative;
  line-height: 150%;
  text-align: left;
  font-size: var(--font-size-2xs);
  color: var(--gray-1);
  font-family: var(--font-monaco);
}

.separator {
  position: relative;
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
}

.imaginedBy {
  margin: 0;
}

.headerContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 110%;
  text-transform: uppercase;
  color: var(--purple);
}

.chineseBornArtistCaiContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--xl-body-text-size);
  letter-spacing: 0.02em;
  line-height: 140%;
  flex-shrink: 0;
}

.learnMore {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
  z-index: 10;
}

.learnMoreWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--purple);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--gray-5);
  margin-top: 16px;
  font-family: var(--font-monaco);
  box-shadow: 0px 0px 20px rgba(115, 92, 229, 0.5);
  transition: box-shadow 0.2s ease-in-out;
}

.learnMoreWrapper:hover {
  box-shadow: 0px 0px 40px rgba(115, 92, 229, 1);
}

.caption {
  font-family: 'Monaco';
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: var(--gray-1);
  opacity: 0.7;
  width: 100%;
}

.contentright {
  width: 520px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-37xl) var(--padding-21xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}

.caisection,
.frameParent {
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.caisection {
  max-width: var(--content-width);
  width: 100%;
  flex-direction: column;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--gray-1);
  font-family: var(--xl-body-text);
  padding-top: 24px;
}

@media screen and (max-width: 980px) {
  .frameParent {
    flex-direction: column;
    gap: 32px;
  }

  .contentright {
    max-width: 480px;
    width: 100%;
  }

  .frameWrapper {
    display: flex;
    max-width: 100%;
    justify-content: fle;
    align-items: center;
    z-index: 1;
  }

  .frameChild {
    max-width: 70%;
    transition: max-width 0.2s ease;
  }

  .frameParent {
    flex-direction: column-reverse;
  }

}
.herosection {
  text-align: center;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  max-width: var(--content-width);
  font-size: var(--font-size-base);
  color: var(--eet-green3);
  font-family: var(--xl-body-text);
}

.header {
  background: linear-gradient(180deg, #000 13.54%, transparent);
  width: 100vw;
  position: fixed;
  z-index: 10000000;
  height: 96px;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-45xl);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerWrapper {
  max-width: var(--content-width);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.askQuestion {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}
.buttonWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green3);
  box-shadow: 0 0 20px rgba(47, 192, 159, 0.8);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.buttonWrapper:hover {
  box-shadow: 0px 0px 40px rgba(47, 192, 159, 0.8);
}

.textWrapper {
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-size: var(--font-size-17xl);
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-content: center;
  gap: var(--gap-46xl);
  z-index: 10;
}

.eetLogoAnimation1Icon {
  position: relative;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,0) 50%);
}

.eet {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 110%;
  text-transform: uppercase;
}

.logo {
  width: 200px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--sm-header-size);
}

.about {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  color: var(--gray-5);
}

.secondaryCta {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 140%;
  text-transform: uppercase;
}

.connectbutton {
  border-radius: var(--br-5xs);
  background-color: var(--color-gray-100);
  border: 1px solid var(--eet-green3);
  box-sizing: border-box;
  height: 41px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonwrapper,
.connectbutton,
.header {
  flex-shrink: 0;
  display: flex;
}

.buttonwrapper {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-family: var(--font-monaco);
}

.enterAConversation {
  margin: 0;
}

.enterAConversationContainer {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 120%;
  text-transform: uppercase;
}

.establishedByCai {
  position: relative;
  font-size: 18px;
  line-height: 140%;
  color: var(--gray-5);
  margin-bottom: -4px;
}

.eetIntro {
  position: relative;
  font-size: var(--xl-subhead-size);
  line-height: 140%;
  margin-top: 40px;
  color: var(--gray-5);
  max-width: 600px;
}

.enterAConversationWithTheParent {
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}

.consultOracle {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}

.consultOracleWrapper,
.text {
  display: flex;
  align-items: center;
}

.consultOracleWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green3);
  box-shadow: 0 0 20px rgba(47, 192, 159, 0.8);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
}

.frameChild,
.textWrapper {
  position: relative;
  flex-shrink: 0;
}

.frameChild {
  width: 48.32px;
  height: 9px;
}

.herosection,
.learnMoreParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.learnMoreParent {
  width: 100%;
  gap: var(--gap-5xs);
  font-size: var(--font-size-xs);
  color: var(--gray-5);
  font-family: var(--font-monaco);
  animation: bounce 5s infinite;
  margin-bottom: -120px;
  z-index: 20;
}

.bottomGradient {
  width: 100vw;
  height: 150px;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 10;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

@media only screen and (max-width: 1520px) {

  .establishedByCai {
    display: none;
  }

  .text {
    margin-top: 32px;
  }

  .herosection {
    height: 100vh;
  }

}

@media only screen and (max-width: 980px) {
  .links {
    display: none;
  }

  .header {
    padding: 0 16px;
  }

  .eetLogoAnimation1Icon {
    width: 48px;
    height: 48px;
  }

  .eet {
    font-size: 20px;
  }

  .header {
    height: 64px;
  }

  .connectbutton {
    width: unset;
    padding: 12px;
    font-size: 14px;
    height: 32px;
    line-height: 14px !important;
  }

  .secondaryCta {
    line-height: 100%;
  }

  .logo {
    width: unset;
    gap: 8px;
  }

  .establishedByCai {
    font-size: 14px;
    display: none;
  }

  .herosection {
    height: var(--mobile-height);
    min-height: 500px;
  }

  .text {
    gap: 40px;
  }

  .enterAConversationWithTheParent {
    padding-top: 0;
    padding-bottom: 0;
  }

  .eetIntro {
    margin-top: 24px;
  }

  .textWrapper {
    margin-top: 32px;
  }

}
 
.separator {
  position: relative;
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
  mix-blend-mode: screen;
}
.eetAnswersLifes,
.eetReadingsCan {
  position: relative;
  letter-spacing: 0.02em;
  display: inline-block;
}
.eetAnswersLifes {
  line-height: 110%;
  text-transform: uppercase;
  width: 432px;
}
.eetReadingsCan {
  font-size: var(--xl-body-text-size);
  line-height: 140%;
  color: var(--gray-6);
  width: 520px;
  padding: 0 32px;
}
.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.past,
.whatShouldI {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
}
.whatShouldI {
  align-self: stretch;
  min-height: 32px;
}
.whatShouldIKnowAboutMyPaParent {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--gray-6);
  font-family: var(--font-monaco);
}
.frameContainer,
.pastParent,
.whatShouldIKnowAboutMyPaParent {
  display: flex;
  justify-content: flex-start;
}
.pastParent {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-16xl);
  align-items: center;
  gap: var(--gap-5xl);
}
.frameContainer {
  width: 1058px;
  flex-direction: row;
  align-items: flex-start;
  opacity: 0.9;
}
.consultOracle {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}
.consultOracleWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green3);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 0px 20px rgba(47, 192, 159, 0.8);
}

.consultOracleWrapper:hover {
  box-shadow: 0px 0px 40px rgba(47, 192, 159, 0.8);
}

.frameWrapper {
  align-self: stretch;
  background: linear-gradient(180deg, transparent, #000);
  z-index: 10000;
  flex-shrink: 0;
  height: 200px;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: -200px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
}
.frameGroup {
  text-align: left;
  width: 100%;
  font-size: var(--xl-body-text-size);
}
.frameGroup,
.frameParent,
.questionsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.frameParent {
  width: 100%;
  gap: var(--gap-53xl);
}
.questionsection {
  gap: var(--gap-9xl);
  max-width: var(--content-width);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--eet-green3);
  font-family: var(--xl-body-text);
  margin-bottom: 64px;
}

.innerFrameContainer > .pastParent:nth-child(n+4) {
  display: none;
}

.innerFrameContainer {
  display: flex;
  max-width: var(--content-width);
  flex-direction: row;
  align-items: flex-start;
  opacity: 0.9;
}

@keyframes slideDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(calc(-100% + 50vh)); /* Adjust this value based on the total height of the children elements minus the visible frame height */
  }
}

@media screen and (max-width: 980px) {

  .contentWrapper {
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .gradientOverlay {
    height: 160px;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }

  .separatorWrapper {
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8491771708683473) 50%);
    z-index: 100;
  }

  .eetReadingsCan {
    max-width: 480px;
    width: unset;
    background-color: var(--colors-black);
    padding-bottom: 24px;
  }

  .whatShouldI {
    min-height: unset;
  }

  .questionsection {
    gap: 32px;
    margin-top: 0px;
  }

  .frameParent {
    width: 100%;
  }

  .questionsection {
    padding: 0 24px;
  }

  .eetAnswersLifes {
    background-color: var(--colors-black);
    max-width: 100%;
    padding: 24px 0;
  }

  .frameContainer {
    height: 80vh;
    display: block;
    overflow: hidden;
  }

  .frameGroup {
    margin-top: -40vh;
  }

  .innerFrameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    animation: slideDown 600s linear infinite;
  }

  .innerFrameContainer > .pastParent:nth-child(n+4) {
    display: flex;
  }

  .pastParent {
    max-width: 80vw;
    padding: 0 24px;
  }

  .questionsection {
    max-width: 100vw;
  }

}

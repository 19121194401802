.shouldILong {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 140%;
  display: inline-block;
  width: 143px;
  flex-shrink: 0;
}
.shouldILongRiskAssetsContainer,
.shouldILongRiskAssetsWrapper {
  position: absolute;
  top: 32px;
  left: calc(50% - 380px);
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-6);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.shouldILongRiskAssetsContainer {
  top: 140px;
  left: calc(50% + 221px);
}
.whatSchoolShould {
  flex: 1;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 140%;
}
.whatSchoolShouldIApplyToWrapper {
  position: absolute;
  top: 94px;
  left: calc(50% - 14px);
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  width: 189px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.isItTime {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 140%;
}
.isItTimeToSellContainer,
.isItTimeToSellWrapper,
.isTheBottomInContainer,
.isTheBottomInWrapper {
  position: absolute;
  top: 0;
  left: calc(50% - 132px);
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-6);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.isItTimeToSellContainer,
.isTheBottomInContainer,
.isTheBottomInWrapper {
  top: 160px;
  left: calc(50% - 453px);
}
.isTheBottomInContainer,
.isTheBottomInWrapper {
  top: 187px;
  left: calc(50% - 172px);
}
.isTheBottomInContainer {
  top: 16px;
  left: calc(50% + 131px);
}
.frameContainer {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  width: 1034px;
  height: 253px;
  overflow: hidden;
}
.shouldILongRiskAssetsFrame,
.shouldILongRiskAssetsWrapper1 {
  position: absolute;
  top: 32px;
  left: calc(50% + 254.5px);
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-6);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.shouldILongRiskAssetsWrapper1 {
  top: 140px;
  left: calc(50% - 456.5px);
}
.whatSchoolShouldIApplyToContainer {
  top: 116px;
  left: calc(50% + 35.5px);
  box-sizing: border-box;
  width: 189px;
}
.isItTimeToSellFrame,
.isItTimeToSellWrapper1,
.isTheBottomInFrame,
.isTheBottomInWrapper1,
.whatSchoolShouldIApplyToContainer,
.whatSchoolShouldIApplyToFrame {
  position: absolute;
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-6);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.whatSchoolShouldIApplyToFrame {
  top: 32px;
  left: calc(50% - 550.5px);
  box-sizing: border-box;
  width: 189px;
}
.isItTimeToSellFrame,
.isItTimeToSellWrapper1,
.isTheBottomInFrame,
.isTheBottomInWrapper1 {
  top: 204px;
  left: calc(50% - 98.5px);
}
.isItTimeToSellWrapper1,
.isTheBottomInFrame,
.isTheBottomInWrapper1 {
  top: 160px;
  left: calc(50% + 244.5px);
}
.isTheBottomInFrame,
.isTheBottomInWrapper1 {
  top: 10px;
  left: calc(50% - 89.5px);
}
.isTheBottomInWrapper1 {
  top: 72px;
  left: calc(50% - 329.5px);
}
.frameDiv {
  position: absolute;
  top: 229px;
  left: 32px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  width: 1101px;
  height: 253px;
  overflow: hidden;
}
.frameGroup {
  position: absolute;
  top: 54px;
  left: -275px;
  width: 1133px;
  height: 482px;
  opacity: 0.19;
}
.shotsSo1Icon {
  position: absolute;
  top: -32px;
  left: -64px;
  width: 700px;
  height: auto;
  object-fit: cover;
}
.frameParent {
  position: relative;
  width: 656px;
  height: 588px;
  flex-shrink: 0;
}
.aFortuneInterpretated {
  margin: 0;
}
.aFortuneInterpretatedContainer,
.everyQuestionTo {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.aFortuneInterpretatedContainer {
  line-height: 110%;
  text-transform: uppercase;
}
.everyQuestionTo {
  font-size: var(--xl-body-text-size);
  line-height: 140%;
  color: var(--gray-6);
}
.askQuestion {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 150%;
  text-transform: uppercase;
}
.askQuestionWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--eet-green3);
  box-shadow: 0 0 20px rgba(47, 192, 159, 0.8);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--colors-black);
  font-family: var(--font-monaco);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.askQuestionWrapper:hover {
  box-shadow: 0px 0px 40px rgba(47, 192, 159, 0.8);
}

.contentright {
  width: 520px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-37xl) var(--padding-21xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--eet-green3);
  font-family: var(--xl-body-text);
}
.intelligencesection {
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--gray-6);
  font-family: var(--font-monaco);
}

@media only screen and (max-width: 980px) {
  .intelligencesection {
    flex-direction: column-reverse;
    margin-bottom: -120px;
    gap: 48px;
  }

  .contentright {
    max-width: 480px;
    padding-bottom: 0px;
    width: 100%;
  }

}